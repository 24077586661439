




























import { Vue, Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { apiBuilder } from '../../config';
import axios from 'axios';

const auth = namespace('auth');

@Component
export default class ChangePassword extends Vue {
	private rules = {
		required: (value: string | number) => !!value || 'Không Được Trống.',
	};

	private form = {
		oldPassword: '',
		newPassword: '',
		cfNewPassword: '',
	};

	private inputDisable = false;

	private loading = false;

	@auth.State
	private token!: string;

	@auth.State
	private username!: string;

	// COMPUTED

	get passwordConfirmationRule() {
		return this.form.newPassword === this.form.cfNewPassword || 'Mật Khẩu Không Trùng';
	}

	//METHOD

	private changePassword(): void {
		this.inputDisable = true;
		this.loading = true;

		const apiUrl = apiBuilder('account/changepassword.php');

		const param = {
			params: {
				username: this.username,
				token: this.token,
				oldpassword: this.form.oldPassword,
				password: this.form.newPassword,
				confirmpassword: this.form.cfNewPassword,
			},
		};

		axios
			.post(apiUrl, null, param)
			.then((res) => {
				// console.log(res.data)

				const error = res.data.error;

				if (error == '181') {
					return this.$swal({
						icon: 'error',
						title: 'Lỗi !',
						text: 'Tên Đăng Nhập Không Tồn Tại !',
					});
				} else if (error == '182') {
					return this.$swal({
						icon: 'error',
						title: 'Lỗi !',
						text: 'Mật Khẩu Cũ Sai !',
					});
				} else if (error == '183') {
					return this.$swal({
						icon: 'error',
						title: 'Lỗi !',
						text: 'Mật Khẩu Mới Và Nhập Lại Không Trùng !',
					});
				} else if (error == '184') {
					return this.$swal({
						icon: 'error',
						title: 'Lỗi !',
						text: 'Mật Khẩu Mới Và Mật Khẩu Cũ Không Nên Trùng Nhau !',
					});
				} else if (error != '180') {
					return this.$swal({
						icon: 'error',
						title: 'Lỗi !',
						text: 'Server Error! Please Reload !',
					});
				} else {
					// this.form.oldPassword = null
					// this.form.newPassword = ""
					// this.form.cfNewPassword = ""
					(this.$refs.form as Vue & { reset: () => boolean }).reset();

					return this.$swal({
						icon: 'success',
						title: 'Thành Công !',
						text: 'Đổi Mật Khẩu Thành Công !',
					});
				}
			})
			.catch((e) => {
				return this.$swal({
					icon: 'error',
					title: 'Lỗi !',
					text: e,
				});
			});

		this.inputDisable = false;
		this.loading = false;
	}
}
